class WsMediaController {

    constructor() {
        this._accessPermitted = false
        this._videoDevices = []
        this._videoDevicesSelect = []
        this._audioDevices = []
        this._audioDevicesSelect = []
    }

    async init() {
        try {

            await navigator.mediaDevices.getUserMedia({ video: true });
            await navigator.mediaDevices.getUserMedia({ audio: true });
            this._accessPermitted = true
            await this.prepareMediaDevices()

        } catch(e) {
            this._accessPermitted = false
            return false
        }

        return true

    }

    async prepareMediaDevices() {
        try {
            console.log('prepareMediaDevices')
            const devices = await navigator.mediaDevices.enumerateDevices();

            console.log('prepareMediaDevices' , devices)

            this._audioDevices = devices.filter(el => el.kind === 'audioinput' )
            this._videoDevices = devices.filter(el => el.kind === 'videoinput' )

            this._videoDevicesSelect = this._videoDevices.map(el => ({text : el.label , value: el.deviceId}))
            this._audioDevicesSelect = this._audioDevices.map(el => ({text : el.label , value: el.deviceId}))

        } catch (error) {
            console.log('failed to get media devices' , error.message, error.stack)
        }

    }

    isPermitted() {
        return this._accessPermitted
    }

    getCamerasSelect() {
        return this._videoDevicesSelect
    }

    getMicrophonesSelect() {
        return this._audioDevicesSelect
    }

}

export default WsMediaController
