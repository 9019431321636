<template>
  <div>
  </div>
</template>

<script>
import webinars from "@/modules/webinars/mixins/webinars";
export default {
    name: 'wsCountDown.vue',
    mixins: [webinars],
    props: {
       previewDialog: {
         type: Boolean,
         default: false
       },
    },
   data() {
     return {
       countdownMessage: '',
       hour: 0,
       minute: 0,
       second: 0,
     }
   },
   computed: {
   },
   methods: {
     setTimeLabel(name, value) {
       const label = this.$tc(name, value).split(' ');
       if (!label.length) return '';

       const timeLabel = label[1];
       return timeLabel[0].toUpperCase() + timeLabel.slice(1);
     },

   },
   mounted() {
   },
  watch: {
  }
 }

</script>

<style scoped>

</style>
